import React from "react";
import Layout from "../components/reusable/Layout";
import HeroSection from "../components/reusable/HeroSection";
import First from "../components/digital-campus/fisrt";
import Slides from "../components/digital-campus/Slides";
import Partners from "../components/digital-campus/Partners";
import Quotes from "../components/digital-campus/Quotes";
import SimpleParagraph from "../components/reusable/SimpleParagraph";
import { graphql } from "gatsby";
import Carousel1 from "../components/digital-campus/Carousel1";
import Seo from "../components/reusable/Seo";

const DigitalCampus = () => {
  return (
    <>
      <Seo />
      <Layout>
        <HeroSection
          data={{
            imageName: "heroDigital.png",
            hasButtons: true,
          }}
          nameSpace="digitalCampus"
          withGradient
        />
        <Partners
          logos={[
            "unis3.png",
            "unis4.png",
            "unis2.png",
            "unis1.png",
            "unis5.png",
          ]}
        />
        <First />
        <Carousel1
          nameSpace="digitalCampus"
          isFromDigital
          info={[
            {
              title: "carouselFirstTitle",
              desc: "carouselFirstDesc",
              img: "carouDigital1.png",
            },
            {
              title: "carouselSecondTitle",
              desc: "carouselSecondDesc",
              img: "carouDigital2.png",
            },
            {
              title: "carouselThirdTitle",
              desc: "carouselThirdDesc",
              img: "carouDigital3.png",
            },
          ]}
        />
        <Slides
          data={[
            {
              red: "dcRed1",
              title: "dcTitle1",
              desc: "dcDesc1",
              button: "dcButton1",
              image: "slidesTrois.png",
              bg: "#A6BFCD",
              to: "/contact",
            },
            {
              red: "dcRed2",
              title: "dcTitle2",
              desc: "dcDesc2",
              button: "dcButton2",
              image: "digital2.png",
              bg: "#F5C987",
              to: "/contact",
            },
            {
              red: "dcRed3",
              title: "dcTitle3",
              desc: "dcDesc3",
              button: "dcButton3",
              image: "digital3.png",
              bg: "#EFB7B0",
              to: "/contact",
            },
          ]}
          fromDigital
        />
        <SimpleParagraph nameSpace="digitalCampus" />
        <Partners
          paddingAll
          logos={[
            "partner1.png",
            "partner5.png",
            "partner4.png",
            "partner2.png",
            "partner3.png",
          ]}
        />
        <Quotes
          data={[
            {
              quote: "quotesFirst",
              author: "authorFirst",
            },
            {
              quote: "quotesSecond",
              author: "authorSecond",
            },
            {
              quote: "quotesThird",
              author: "authorThird",
            },
          ]}
          nameSpace="digitalCampus"
        />
      </Layout>
    </>
  );
};

export default DigitalCampus;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
